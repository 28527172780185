








































import { Component, Vue } from "vue-property-decorator";
import { ISms, Sms } from '@/utils/Sms'
import { apiUser } from '@API/ApiUser'

@Component({
  name: "register",
  components: {},
})

export default class Home extends Vue {
  private sms: ISms = new Sms()
  private form: any = {
    mobile: '',
    pwd: '',
    code: '',
  }


  created() {
  }

  handleSendSms() {
    this.sms.sendSms(this.form.mobile, 'register')
  }


  async register() {
    // check
    let form = this.form
    if (form.mobile.length !== 11) {
      this.$toast.fail('请输入正确的手机号码');
      return
    }
    if (!form.code) {
      this.$toast.fail('请输入验证码');
      return
    }

    const loading = this.$toast.loading({
      message: '加载中...',
      duration: 0,
    })
    // request

    apiUser.register({
      username: form.mobile,
      code: form.code
    })
      .finally(() => {
        loading.clear()
      })
      .then(res => {
        let $a = document.createElement('a')
        $a.href = res.data.url
        $a.click()
      })
      .catch(e => {
        this.$dialog({
          title: '注册失败',
          message: e.message
        })
      })
  }
}
